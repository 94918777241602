import React, { useEffect, useState } from "react";
import {
  add_medpay_products,
  each_category,
  medpay_category,
} from "../../utils";
import axios from "axios";
import Select from "react-select";
import ItemCategory from "./ItemCategory";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button, Checkbox } from "@mui/material";
import EachItem from "./EachItem";
import AreYouSure from "./AreYouSure";
import { useNavigate, useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const CategorySelect = () => {
  //snackbar
  const action = <React.Fragment>success</React.Fragment>;
  const [open1, setOpen1] = React.useState(false);

  const handleClick1 = () => {
    setOpen1(true);
  };

  const handleClose1 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen1(false);
  };
  //areyousure
  const navigate = useNavigate();
  const params = useParams();
  console.log(params, "testing");

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [finalData, setFinalData] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //final
  const submitDataFinal = () => {
    const bodyData = {
      ...params,
      products: finalData.toString(),
    };
    axios
      .post(add_medpay_products, bodyData)
      .then((res) => {
        handleClick1();
        setTimeout(() => {
          navigate("/medpay");
        }, 2000);
      })
      .catch((err) => console.log(err, "finalerr"));
  };

  const [catData, setCatData] = useState([]);
  const [allCatData, setAllCatData] = useState([]);
  const [uncheckedData, setUncheckedData] = useState([]);
  //   const [selectedCat, setSelectedCat] = useState([]);
  const [tempData, setTempData] = useState(allCatData);
  // console.log(allCatData, 'chat');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading3, setIsLoading3] = useState(false);

  const getCatData = () => {
    setIsLoading(true);
    axios
      .get(medpay_category)
      .then((res) => {
        setIsLoading(false);

        let data = res.data.categories.map((item) => ({
          label: item.category,
          value: item.category,
        }));
        let item = { label: "All", value: "All" };
        data.unshift(item);
        setCatData(data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  };
  const allData = () => {
    setIsLoading2(true);
    axios
      .get(each_category)
      .then((res) => {
        setIsLoading2(false);
        console.log(res.data, "cehck");
        let ot = res.data.products.map((item) => ({ ...item, checked: true }));
        setAllCatData(ot);
        setTempData(ot);
        let data = res.data.categories.map((item) => ({
          label: item.category,
          value: item.category,
        }));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading2(false);
      });
  };

  useEffect(() => {
    getCatData();
    allData();
  }, []);

  const handleChange = (e) => {
    // console.log(e.value);
    setIsLoading(true);
    let data = [...allCatData].filter((item) => item.category == e.value);
    setIsLoading(false);
    setTempData(data);
    if (e.value == "All") {
      setIsLoading(true);
      setTimeout(() => {
        setTempData(allCatData);
        setIsLoading(false);
      }, 1000);
    }

    // console.log(data, 'faak');
  };
  if (isLoading) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            height: "80vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      </>
    );
  }
  return isLoading || isLoading2 ? (
    <>
      <Box
        sx={{
          display: "flex",
          height: "80vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    </>
  ) : (
    <div style={{ padding: "4%" }}>
      <div
        style={{
          display: "flex",
          gap: "10px",

          flexDirection: "column",
        }}
      >
        <div>Select Category</div>
        <div>
          <Select
            defaultValue={[catData[0]]}
            // isMulti
            name="colors"
            options={catData}
            onChange={handleChange}
            className="basic-multi-select"
            classNamePrefix="select"
            //   menuIsOpen
          />
        </div>
      </div>
      <br />
      {/* <div style={{ height: '65vh', overflowY: 'scroll', padding: '20px' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: ' 15px 40px' }}>
          {tempData.map((item) => (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  // alignItems: 'center',
                  // border: '1px solid black',
                  width: '30%',
                  height: '80px',
                  padding: '10px ',
                  borderRadius: '10px',
                  boxShadow:
                    'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ minWidth: '300px', fontSize: '12px' }}>
                    {item.name}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '12px',
                    }}
                  >
                    <div>Cost </div>
                    <div>&nbsp;:</div>
                    <div>&nbsp;₹{item.cost}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '12px',
                  }}
                >
                  <div style={{ minWidth: '300px' }}>
                    Varaint&nbsp;:&nbsp;{item.variant}
                  </div>
                  <div>
                    {' '}
                    <Checkbox
                      defaultChecked
                      onClick={(e) => console.log(e.target, item)}
                    />
                  </div>
                </div>
              </div>
              
            </>
          ))}
        </div>
      </div> */}
      <EachItem
        tempData={tempData}
        uncheckedData={uncheckedData}
        setUncheckedData={setUncheckedData}
        setTempData={setTempData}
        setAllCatData={setAllCatData}
        isLoading3={isLoading3}
        setIsLoading3={setIsLoading3}
      />
      <div
        style={{
          paddingTop: "30px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            let data = Array.from(
              { length: allCatData.length },
              (_, i) => i + 1
            ).filter((item) => !uncheckedData.includes(item));
            setFinalData(data);

            handleClickOpen();
          }}
        >
          Submit
        </Button>
      </div>
      <AreYouSure
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        uncheckedData={uncheckedData}
        checkeddata={allCatData.length - uncheckedData.length}
        open={open}
        handleClose={handleClose}
        submitDataFinal={submitDataFinal}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open1}
        autoHideDuration={3000}
        onClose={handleClose1}
        message="Note archived"
      >
        <Alert onClose={handleClose1} severity="success" sx={{ width: "100%" }}>
          This is a success message!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CategorySelect;
