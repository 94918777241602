import React from "react";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import { useParams } from "react-router-dom";
import { Style } from "@mui/icons-material";
const FridayAi = () => {
  document.body.style.backgroundColor = "black";
  const [data, setData] = React.useState([]);
  const loc = useParams();
  React.useEffect(() => {
    setInterval(() => {
      axios
        .get(
          `https://farming-backend.taskmo.in/test/funfriday/${loc.sow1}/${loc.sow2}`
        )
        .then((res) => {
          setData(res.data.leads);
          console.log(res, "res");
        })
        .catch((err) => console.log(err));
    }, 5000);
    console.log(loc.logo2);
    
    
  }, []);
  
  const calculate = (nu1,nu2)=>{
   return (nu1/nu2)*100
  } 
  const c1 = (data.one/loc.target1)*100;
  const c2 = (data.two/loc.target2)*100;
   
 
  return (
    
    <div>
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            color: "#FFD700",
            display: "flex",
            justifyContent: "center",
            marginTop: "100px",
            marginBottom: "100px",
            fontSize: "50px",
            fontWeight: 600,
          }}
        >
          <span
            style={{
              border: "1px solid #FFD700",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            {" "}
            BONUS - {loc.bonus}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <div>
              <Avatar
                alt="Amazon"
                sx={{ width: "250px", height: "250px" }}
                src={data.logo1}
              />
            </div>
            <div
              style={{
                color: "#fff",
                marginTop: "30px",
                fontSize: "40px",
              }}
            >
              Leads
            </div>
            <div style={{ color: "#fff", fontSize: "80px", fontWeight: 600 }}>
              {data.one}/{loc.target1}
            </div>
            <div style={{ color: c1 > c2 ? '#00ff00' :'#ff0000', fontSize: "80px", fontWeight: 600 }} >
              {calculate(data.one,loc.target1).toFixed(2)} %</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <div>
              <ThumbsUpDownIcon style={{ color: "white", fontSize: "150px" }} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <div>
              <Avatar
                alt="Remy Sharp"
                sx={{ width: "250px", height: "250px" }}
                src={data.logo2}
              />
            </div>
            <div
              style={{
                color: "#fff",
                marginTop: "30px",
                fontSize: "40px",
              }}
            >
              Leads
            </div>
            <div style={{ color: "#fff", fontSize: "80px", fontWeight: 600 }}>
              {data.two}/{loc.target2}
            </div>
             <div style={{ color: c2 > c1 ? '#00ff00' :'#ff0000', fontSize: "80px", fontWeight: 600 }} >
            {calculate(data.two,loc.target2).toFixed(2)} %
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FridayAi;
