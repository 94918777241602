import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { download, lead_details_medpay } from '../../utils';
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';

import moment from 'moment';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useLocation, useNavigate } from 'react-router-dom';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DataTableExtensions from 'react-data-table-component-extensions';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';

// import "react-data-table-component-extensions/dist/index.css";
import './Medpay.css';
import { Icon } from '@mui/material';
const customStyles = {
  table: {
    borderRadius: '100px',
  },
  rows: {
    style: {
      minHeight: '72px', // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: '10px', // override the cell padding for head cells
      paddingRight: '8px',
      background: '#1875D1',
      color: '#fff',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
    },
  },
};
const conditionalRowStyles = [
  {
    when: (row) => Number(row.id) % 2 === 0,
    style: {
      background: '#f7f7f7',
      border: 'none',
      color: 'black',
      cursor: 'pointer',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  {
    when: (row) => Number(row.id) % 2 !== 0,
    style: {
      background: '#ffffff',
      border: 'none',
      color: 'black',
      cursor: 'pointer',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
];

function Medpay() {
  const [dataLead, setDataLead] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [err, setisErr] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.pathname, 'testinglocation');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let { data } = await axios.get(lead_details_medpay);

        let leadsdatas = data?.leads.map((item, i) => ({ ...item, id: i }));
        setDataLead(leadsdatas);
        setIsLoading(false);
      } catch (error) {
        setisErr(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const columns = [
    {
      name: 'Lead Id',
      center: true,
      selector: (row) => row.main_lead_id,
      sortable: true,
    },
    {
      name: 'Merchant Name',
      selector: (row) => row.merchant_name,
      sortable: true,
    },
    {
      name: 'Merchant Number',
      selector: (row) => row.merchant_number,
      sortable: true,
    },
    {
      name: 'Pincode',
      selector: (row) => row.pincode,
      sortable: true,
    },
    {
      name: 'Date',
      selector: (row) =>
        moment(row.created_on).utcOffset(330).format('DD-MM-YYYY'),
      sortable: true,
    },
    {
      name: 'Shop Name',
      selector: (row) => (row.shop_name == '' ? NaN : row.shop_name),
      sortable: true,
    },
    {
      name: 'Shop Number',
      selector: (row) => row.shop_contact_number,
      sortable: true,
    },
    {
      right: true,
      name: 'Download',
      center: true,
      cell: (d) => (
        <div
          onClick={() => {
            if (d.catalouge_status == 'no') {
              if (location.pathname === '/medpay-client') {
                return '';
              } else {
                navigate(`/category/${d.main_lead_id}/${d.lead_id}`);
              }
            } else {
              if (location.pathname === '/medpay-client') {
              } else {
                navigate(`/categoryedit/${d.main_lead_id}/${d.lead_id}`);
              }
            }
          }}
          style={{ paddingRight: '10px' }}
        >
          {d.catalouge_status == 'no' ? (
            <>
              {' '}
              {location.pathname === '/medpay-client' ? (
                <DownloadIcon disabled style={{ opacity: '0.5' }}>
                  {' '}
                  <DownloadIcon />
                </DownloadIcon>
              ) : (
                <VisibilityIcon />
              )}
            </>
          ) : (
            <>
              {' '}
              {location.pathname === '/medpay-client' ? (
                <a
                  href={`${download}?lead_id=${d.main_lead_id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  <DownloadIcon />{' '}
                </a>
              ) : (
                <ModeEditIcon />
              )}
            </>

            // <VisibilityOffIcon />
          )}
        </div>
      ),
    },
  ];
  const tableData = {
    columns,
    data: dataLead,
  };
  return (
    <div style={{ padding: '2%' }}>
      <div
        style={{
          fontWeight: 600,
          color: '#1875D1',
          float: 'left',
          marginTop: '30px',
          fontSize: '20px',
        }}
      >
        Medpay Cataloging - Lead Data
      </div>
      <DataTableExtensions
        {...tableData}
        export={false}
        print={false}
        filterPlaceholder={`Search`}
      >
        <DataTable
          // title={
          //   <div style={{ fontWeight: 600, color: '#663595' }}>
          //     Medpay Cataloguing - Lead Data
          //   </div>
          // }
          columns={columns}
          data={dataLead}
          pagination
          progressPending={isLoading}
          fixedHeader
          fixedHeaderScrollHeight="65vh"
          selectableRowsHighlight
          highlightOnHover
          // subHeader
          // subHeaderAlign="left"
          conditionalRowStyles={conditionalRowStyles}
          // onRowClicked={handleOpenLeads}
          customStyles={customStyles}
        />
      </DataTableExtensions>
    </div>
  );
}

export default Medpay;
