import React from "react";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
const Bubble = () => {
  document.body.style.backgroundColor = "black";
  const [data, setData] = React.useState([]);
  //const [count, setCount] = React.useState(1);
  React.useEffect(() => {
    setInterval(() => {
      axios
        .get("https://farming-backend.taskmo.in/test/daily_count")
        .then((res) => {
          setData(res.data.leads);
          console.log(res, "res");
        })
        .catch((err) => console.log(err));
    }, 5000);
  }, []);

  return (
    <div>
      <div
        style={{
          // height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        {data.map((res) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div>
                <Avatar
                  alt="Amazon"
                  // sx={{ width: "250px", height: "250px" }}
                  src={res.brand_logo}
                  style={{
                    transform: `scale(${res.count})`,
                    width: `${res.count * 2}px`,
                    height: `${res.count * 2}px`,
                  }}
                />
              </div>
              <div
                style={{
                  color: "#fff",
                  marginTop: "30px",
                  fontSize: "40px",
                }}
              >
                Leads
              </div>
              <div style={{ color: "#fff", fontSize: "80px", fontWeight: 600 }}>
                {res.count}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Bubble;
