const lead_details_medpay =
  'https://farming-backend.taskmo.in/project/lead_details_medpay';

const medpay_category =
  'https://farming-backend.taskmo.in/project/medpay_category';

const each_category =
  'https://farming-backend.taskmo.in/project/medpay_products';

const add_medpay_products =
  'https://farming-backend.taskmo.in/project/add_medpay_products';

const editproduct =
  'https://farming-backend.taskmo.in/project/edited_medpay_products';

const editfinalsubmit =
  ' https://farming-backend.taskmo.in/project/edit_medpay_products_details';

const download =
  'https://farming-backend.taskmo.in/project/medpay_products_download';
export {
  lead_details_medpay,
  medpay_category,
  each_category,
  add_medpay_products,
  editproduct,
  editfinalsubmit,
  download,
};
