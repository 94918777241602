//import logo from "./logo.svg";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/home/Home';
import FridayAi from './Components/FridayFrustation/FridayAi';
import Bubble from './Components/bubble/Bubble';
import Dashbord from './Components/eDash/Dash_E';
import Medpay from './Components/medpay/Medpay';
import CategorySelect from './Components/medpay/CategorySelect';
import CategorySelectEdit from './Components/medpay/CategorySelectEdit';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/business_deck" element={<Home />} />
        <Route
          path="/fun-friday/:sow1/:sow2/:target1/:target2/:bonus"
          element={<FridayAi />}
        />
        <Route path="/bubble" element={<Bubble />} />
        <Route
          path="/cm/:cmids/:sow1/:sow2/:target1/:target2/:bonus"
          element={<Dashbord />}
        />
        <Route path="/thor" element={<Medpay />} />
        <Route path="/medpay-client" element={<Medpay />} />

        <Route
          path="/category/:main_lead_id/:lead_id"
          element={<CategorySelect />}
        />
        <Route
          path="/categoryedit/:main_lead_id/:lead_id"
          element={<CategorySelectEdit />}
        />
        {/* http://localhost:3001/cm/11,12/1200,1300/1250,1350/100,200/100,300/2000 */}
      </Routes>
    </Router>
  );
}

export default App;
