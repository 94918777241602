import { Checkbox } from '@mui/material';
import React from 'react';

const EachItem = ({
  tempData,
  uncheckedData,
  setUncheckedData,
  isLoading3,
  setIsLoading3,
}) => {
  return (
    <div>
      <div style={{ height: '65vh', overflowY: 'scroll', padding: '20px' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: ' 15px 40px' }}>
          {tempData.map((item) => (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  // alignItems: 'center',
                  // border: '1px solid black',
                  width: '30%',
                  height: '80px',
                  padding: '10px ',
                  borderRadius: '10px',
                  boxShadow:
                    'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ minWidth: '300px', fontSize: '12px' }}>
                    {item.name}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '12px',
                    }}
                  >
                    <div>Cost </div>
                    <div>&nbsp;:</div>
                    <div>&nbsp;₹{item.cost}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '12px',
                  }}
                >
                  <div style={{ minWidth: '300px' }}>
                    Varaint&nbsp;:&nbsp;{item.variant} ({item.category})
                  </div>
                  <div>
                    {' '}
                    <Checkbox
                      // checked={false}
                      value={
                        uncheckedData.includes(item.product_id) ? false : true
                      }
                      checked={
                        uncheckedData.includes(item.product_id) ? false : true
                      }
                      // defaultChecked={
                      //   uncheckedData.includes(item.product_id) ? false : true
                      // }
                      onClick={(e) => {
                        // console.log(item, 'uncehdced', uncheckedData);
                        let ot = uncheckedData.includes(item.product_id);
                        if (ot) {
                          // console.log(ot, 'uncehdced2');

                          let check = uncheckedData.filter(
                            (it) => it !== item.product_id
                          );
                          setUncheckedData(check);
                          // setIsLoading3((prev) => !prev);
                          // console.log('uncehdced3', check, uncheckedData);
                        } else {
                          // setIsLoading3(true);
                          setUncheckedData([...uncheckedData, item.product_id]);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EachItem;
