import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import { useParams } from "react-router-dom";



function Dashbord() {
  document.body.style.backgroundColor = "black";
  
 const loc=useParams()
 
  console.log(loc)
  const BONUS = loc.bonus
  const cmids =  loc.cmids;
  const sow1 = loc.sow1;
  const sow2 = loc.sow2;
  const target = loc.target1.split(',')?.map(val => parseInt(val));
  const target2 = loc.target2.split(',')?.map(val => parseInt(val));
  console.log(target)

const url = 'https://farming-backend.taskmo.in/test/cmcount';
const data = {
  cmids,
  sow1,
  sow2 ,
};


const [comp ,setComp] = useState([]);
const [comp2 ,setComp2] = useState([]);
const [task,setTask] = useState([]);
const [task2,setTask2] = useState([]);
const[profile,setProfile] = useState([]);
const[profile2,setProfile2] = useState([]);
const [cluster,setCluster] = useState([]);
const [cluster2,setCluster2] = useState([])



  console.log('testing')
 const getDAta = ()=>{
   axios.post(url, data)
  .then(response => {
    console.log(response.status);
    console.log(response.data,'testingdata');
    setComp(response.data.l1?.map(item => item.brand_name));
    setComp2((response.data.l2?.map(item => item.brand_name)));
    const ot = response.data.l1?.map(item => item.count);
    console.log(ot,'ot')
    setTask(ot)
    setTask2((response.data.l2?.map(item => item.count)));
    setProfile((response.data.l1?.map(item => item.profile_image)));
    setProfile2((response.data.l2?.map(item => item.profile_image)));
    setCluster((response.data.l1?.map(item => item.cluster_name)));
    setCluster2((response.data.l2?.map(item => item.cluster_name)));

  })
  .catch(error => {
    console.log(error);
  });
 }

 React.useEffect(()=>{
  setInterval(()=>{
    getDAta()
 },10000)
 
 },[])





  console.log(task,'task')
  

  const percentage = task?.map((value,key)=>{return(value/target[key])*100}) 
  const Total = percentage?.reduce((value,key)=>{return value+key},0) ;
  const Average = Total / percentage?.length;
console.log(task[0],'per')


  
   const percentage2 = task2?.map((value,key)=>{return(value/target2[key])*100}) 
  const Total2 = percentage2?.reduce((value,key)=>{return value+key},0) ;
  const Average2= Total2 / percentage2.length;





  return (
    
    <div>
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            color: "#FFD700",
            display: "flex",
            justifyContent: "center",
            marginTop: "100px",
            marginBottom: "100px",
            fontSize: "50px",
            fontWeight: 600,
          }}
        >
          <span
            style={{
              border: "1px solid #FFD700",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            {" "}
            BONUS - {BONUS}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <div>
              <Avatar
                alt="Amazon"
                sx={{ width: "250px", height: "250px" }}
                src={profile[0]}
              />
            </div>
            <div
              style={{
                color: "#fff",
                marginTop: "30px",
                fontSize: "40px",
              }}
            >
              {cluster[0]}
            </div>
            <div style={{ color: "#fff", fontSize: "40px", fontWeight: 400, display: "flex" }}>
            <ul style={{listStyleType:'none'}}>
            <label>Company</label>
              {comp?.map((company)=>(<li key={company}>{company} - </li>))}
              </ul>
              <ul style={{listStyleType:'none'}}>
              <label>Achievment</label>
              {task?.map((tasks)=>(<li key={tasks}>{tasks}</li>))}
              </ul>
              <ul style={{listStyleType:'none'}}>
              <label>Target</label>
              {target?.map((targets)=>(<li key={targets}>{targets}</li>))}
              </ul>
            
            </div>
            <p style={{ color:Average>Average2 ? '#00ff00' :'#ff0000', fontSize: "40px", fontWeight: 400}}>{Average.toFixed(2)}%</p>
         
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <div>
              <ThumbsUpDownIcon style={{ color: "white", fontSize: "150px" }} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <div>
              <Avatar
                alt="Remy Sharp"
                sx={{ width: "250px", height: "250px" }}
                src={profile2[0]}
              />
            </div>
            <div
              style={{
                color: "#fff",
                marginTop: "30px",
                fontSize: "40px",
              }}
            >
              {cluster2[0]}
            </div>
            <div style={{ color: "#fff", fontSize: "40px", fontWeight: 400, display: "flex" }}>
             
            <ul style={{listStyleType:'none'}}>
            <label>Company</label>
              {comp2?.map((company)=>(<li key={company}>{company} - </li>))}
              </ul>
              
              <ul style={{listStyleType:'none'}}>
              <label>Achievment</label>
              {task2?.map((tasks)=>(<li key={tasks}>{tasks}</li>))}
              </ul>
              
              <ul style={{listStyleType:'none'}}>
              <label>Target</label>
              {target2?.map((targets)=>(<li key={targets}>{targets}</li>))}
              </ul>
            </div>
            <p style={{ color: Average2>Average ?  '#00ff00' :'#ff0000', fontSize: "40px", fontWeight: 400}}>{Average2.toFixed(2)}%</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashbord;
